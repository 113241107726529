<template>
  <section id="legals">
    <router-link to="/"> &lt; Retour au site</router-link>
    <h1>Mentions légales</h1>
    <p>
      Conformément aux dispositions des Articles 6-III et 19 de la Loi n°2004-575 du 21 juin 2004 pour la Confiance dans
      l’économie numérique, dite L.C.E.N., il est porté à la connaissance des utilisateurs et visiteurs, ci-après
      l""Utilisateur", du site <a href="https://heckmann.dev">https://heckmann.dev</a>
      , ci-après le "Site", les présentes mentions légales.
    </p>
    <p>
      La connexion et la navigation sur le Site par l’Utilisateur implique acceptation intégrale et sans réserve des
      présentes mentions légales.
    </p>
    <p>
      Ces dernières sont accessibles sur le Site à la rubrique « Mentions légales ».
    </p>
    <h2>
      ARTICLE 1 - L'EDITEUR
    </h2>
    <p>
      L’édition et la direction de la publication du Site est assurée par:
      <ul>
        <li>Heckmann Développement</li>
        <li>Numéro de SIRET: 91880454300018</li>
        <li>Responsable éditorial: Cyril Heckmann</li>
        <li>6A rue de Lorraine, 67100 Strasbourg</li>
        <!-- <li>Telephone: <a :href="`tel:0638414001`">0638414001</a></li> -->
        <li>Email: <a :href="`mailto:cyrilheckmann@gmail.com`">cyrilheckmann@gmail.com</a></li>
      </ul>
    </p>
    <p>
      ci-après l'"Editeur".
    </p>
    <h2>
      ARTICLE 2 - L'HEBERGEUR
    </h2>
    <p>
      L'hébergeur du Site est la société SCALEWAY, dont le siège social est situé au 8 rue de la Ville l’Evêque,
      75008 Paris - France.
    </p>
    <h2>
      ARTICLE 3 - CREDITS
    </h2>
    <p>
      Développement: Cyril Heckmann
      <a
        href="https://heckmann.dev"
        target="_blank"
      >
        https://heckmann.dev
      </a>
    </p>
    <h2>
      ARTICLE 4 - ACCES AU SITE
    </h2>
    <p>
      Le Site est accessible en tout endroit, 7j/7, 24h/24 sauf cas de force majeure, interruption programmée ou non et
      pouvant découlant d’une nécessité de maintenance.
    </p>
    <p>
      En cas de modification, interruption ou suspension du Site, l'Editeur ne saurait être tenu responsable.
    </p>
    <!-- ----------- -->

    <h2>ARTICLE 5 - PROPRIÉTÉ INTELLECTUELLE</h2>
    <p>
      Tous les éléments graphiques, la structure et, plus généralement, le contenu du site heckmann.dev sont protégés
      par le droit d’auteur, le droit des marques et le droit des dessins et modèles.
    </p>
    <p>
      Toute personne qui recueille ou télécharge du contenu ou des  informations diffusées sur le site ne dispose sur
      ceux-ci que d’un droit d’usage privé, personnel et non transmissible.
    </p>
    <p>
      La reproduction d’une page du site dans un cadre extérieur à heckmann.dev ou l’insertion d’une page appartenant à
      heckmann.dev dans la page d’un autre site est interdite.
    </p>
    <p>
      De même, toute reproduction ou représentation du site en partie ou en totalité est interdite sans l’accord écrit
      de heckmann.dev et constituerait une contrefaçon sanctionnée par les articles L. 335-2 et suivant du Code de la
      Propriété Intellectuelle.
    </p>
    <p>
      Les textes, graphismes, dessins, logos et photos qui sont diffusés heckmann.dev peuvent faire l’objet d’une
      reproduction sur un support papier ou un support électronique, sous réserve de citer le nom et l’adresse du site
      et de ne pas en faire une utilisation commerciale.
    </p>
    <p>
      Le non-respect des dispositions ci-dessus est susceptible de constituer une contrefaçon engageant la
      responsabilité civile ou pénale de l’auteur de la contrefaçon.
    </p>
    <p>
      La création d’un lien hypertexte vers le site www.espritcycles.com ne peut se faire qu’avec l’autorisation de
      heckmann.dev, et sous réserve qu’aucune confusion ne puisse exister dans l’esprit des internautes sur l’identité
      du site ou la provenance des informations.
    </p>
    <!-- ----------- -->
    <h2>
      ARTICLE 6 - COLLECTE DES DONNEES
    </h2>
    <p>
      Le site est exempté de déclaration à la Commission Nationale Informatique et Libertés (CNIL) dans la mesure où il
      ne collecte aucune donnée concernant les utilisateurs.</p>
    <p>
      Toute utilisation, reproduction, diffusion, commercialisation, modification de toute ou partie du Site, sans
      autorisation de l’Editeur est prohibée et pourra entraînée des actions et poursuites judiciaires telles que
      notamment prévues par le Code de la propriété intellectuelle et le Code civil.
    </p>
  </section>
</template>

<script>

export default {
  name: "Legals",
  mounted() {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  },
};
</script>
