import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

Vue.config.productionTip = false;

Vue.mixin({
  data() {
    return {
      notPreRendered: false,
    };
  },
  mounted() {
    if (!window.__PRERENDER_INJECTED) {
      this.notPreRendered = true;
    }
  },
});

new Vue({
  name: "main",
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
