<template>
  <main>
    <h1>
      Heckmann Développement - Création de site web
    </h1>
    <section id="accueil">
      <div class="container">
        <div class="svg-container">
          <AccueilImg id="accueil_img"/>
        </div>
        <div class="text">
          <h2>
            Vous avez un projet de site web ?
            <div>Vous cherchez un développeur ?</div>
          </h2>
          <p class="description-me">
            Cyril Heckmann, professionnel du développement web depuis 2018, vivant à Strasbourg.
            <br /> J'ai réalisé mes études supérieures dans le domaine du développement informatique à
            l'IUT Robert Schuman de Strasbourg pendant 3ans et suis actuellement en poste en tant que développeur
            fullstack au sein d'une société m'ayant fait confiance pour mon stage, apprentissage et maintenant en CDI.
            <br /> Ici pas de <span class="no-break">Wordpress&nbsp;<i class="fab fa-wordpress"/></span>{{"\xa0"}};
            je travaille essentiellement sur les technologies
            <span class="no-break">JavaScript&nbsp;<i class="fab fa-js" /></span>
            (avec Vue.js) et
            <span class="no-break">Python&nbsp;<i class="fab fa-python"/></span>
            (avec Django) afin de répondre à tout vos besoins les plus précis.
          </p>
        </div>
      </div>
    </section>
    <section id="services">
      <div class="container">
        <div class="text">
          <h2>
            Mes services
          </h2>
          <ul>
            <li :class="{'is-animated fade': !isMobile && notPreRendered, }">
              <div>
                <i class="fa fa-code" />
                <h3>Conception et développement</h3>
                <p>
                  Avec plus de {{exp}} ans d'expérience en développement de solutions web en tout genre,
                  je saurais répondre à tous vos besoins.
                </p>
              </div>
            </li>
            <li :class="{'is-animated fade': !isMobile && notPreRendered, }">
              <div>
                <i class="fa fa-server" />
                <h3>Gestion de l'hébergement</h3>
                <p>
                  Je m'occupe de l'hébergement de votre site, sur des serveurs basés en France.
                  Des sauvegardes régulières sont aussi possibles.
                </p>
              </div>
            </li>
            <li :class="{'is-animated fade': !isMobile && notPreRendered, }">
              <div>
                <i class="fab fa-google" />
                <h3>Optimisation du référencement naturel</h3>
                <p>
                  Un code propre, une sémantique respectée ainsi que des mots-clés bien choisis
                  permettront à votre site d'apparaître dans les premiers résultats de recherche.
                </p>
              </div>
            </li>
            <li :class="{'is-animated fade': !isMobile && notPreRendered, }">
              <div>
                <i class="fa fa-lock" />
                <h3>Mise à jour de sécurité</h3>
                <p>
                  Un site internet doit être accessible 24h/24, les mises à jour de sécurité sont à installer
                  régulièrement afin d'éviter tout désagrément et pour garantir la sécurité de vos utilisateurs.
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </section>
    <section id="presentation">
      <div class="container">
        <div class="svg-container">
          <PresentationImg id="presentation_img"/>
        </div>
        <div class="text">
          <h2>
            Un site web est très important
            <div class="small">
              Quelques chiffres du
              <a
              href="https://www.francenum.gouv.fr/guides-et-conseils/strategie-numerique/
barometre-france-num-2022-le-numerique-dans-les-tpe-pme-0"
              target="_blank"
              >
                "Baromètre France Num 2022"
              </a>
              par la DGE</div>
          </h2>
          <ul>
            <li>
              <h3>Se démarquer de la concurrence</h3>
              <p><strong>68%</strong> des dirigeants affirment que leur entreprise
                <strong>possède un site internet</strong>.</p>
            </li>
            <li>
              <h3>
                Augmentation du nombre de clients
              </h3>
              <p>
                En moyenne, <strong>+15 à +31% de nouveaux clients</strong>  grâce au site internet.
                <br /> <strong>81%</strong> des dirigeants déclarent que le numérique
                représente un bénéfice réel pour leur entreprise
                (<strong>+13%</strong> par rapport à 2020).
              </p>
            </li>
            <li>
              <h3>
                Investissement dans les projets numériques
              </h3>
              <p>
                En 2021, <strong>41 % des entreprises interrogées ont dépensé plus de 1000 €</strong>
                dans le numérique, et 67 % affirment avoir des projets numériques pour
                <strong>les deux prochaines années</strong>
                dont 32 % des organisations prévoient de <strong>dépenser plus de 2000 €.</strong>
              </p>
            </li>
          </ul>
        </div>
      </div>
    </section>
    <section id="portfolio">
      <div class="container">
        <div class="text">
          <h2>
            Mes réalisations
          </h2>
          <Carousel
            :per-page="isMobile ? 1 : (isTablet ? 2 : 3)"
          >
            <Slide
              v-for="projet in projets"
              :key="projet.nom"
            >
              <div class="carousel-img">
                <a
                  v-if="projet.url"
                  :href="projet.url"
                  target="_blank"
                >
                  <img :src="require(`@/assets/img/${projet.img}.png`, )" />
                </a>
                <img
                  v-else
                  :src="require(`@/assets/img/${projet.img}.png`, )"
                />
              </div>
              <h3 v-html="projet.nom" />
              <p class="sous-titre">{{projet.sousTitre}}</p>
              <p class="description">
                <span v-html="projet.description" />
                <a
                  v-if="projet.url"
                  :href="projet.url"
                  target="_blank"
                >
                  {{projet.url}}
                </a>
              </p>
            </Slide>
          </Carousel>
        </div>
      </div>
    </section>
    <section id="contact">
      <div class="container">
        <div class="svg-container">
          <ContactImg id="contact_img"/>
        </div>
        <div class="text">
          <h2>Contactez-moi</h2>
          <ul>
            <li :class="{'is-animated fade': !isMobile && notPreRendered, }">
              <a
              href="mailto:cyrilheckmann@gmail.com"
              target="_blank"
              >
                <i class="fa fa-at" />
                cyrilheckmann@gmail.com
              </a>
            </li>
            <li :class="{'is-animated fade': !isMobile && notPreRendered, }">
              <a
              href="https://www.linkedin.com/in/cyril-heckmann/"
              target="_blank"
              >
                <i class="fab fa-linkedin" />
                Cyril Heckmann
              </a>
            </li>
            <li :class="{'is-animated fade': !isMobile && notPreRendered, }">
              <a
              href="https://discordapp.com/users/259381662897537024"
              target="_blank"
              >
                <i class="fab fa-discord" />
                HectoFR#9766
              </a>
            </li>
          </ul>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";
import AccueilImg from "@/components/icons/AccueilImg.vue";
import PresentationImg from "@/components/icons/PresentationImg.vue";
import ContactImg from "@/components/icons/ContactImg.vue";

export default {
  name: "Home",
  components: {
    AccueilImg,
    PresentationImg,
    ContactImg,
    Carousel,
    Slide,
  },
  data() {
    return {
      projets: [
        {
          img: "cyclotruck",
          nom: "Cyclo&nbsp;Truck",
          sousTitre: "(2022) Design, front et back.",
          url: "https://cyclo-truck.fr",
          description: `
          Site web pour une TPE itinérante de réparation de vélos. Tarifs, services et catalogue de vélos.
          Optimisation du référencement.
          `,
        },
        {
          img: "lestentactrices",
          nom: "Les&nbsp;Tent'Actrices",
          sousTitre: "(2022) Back et aide front.",
          url: "https://lestentactrices.fr",
          description: "Site web pour une troupe de théatre proposant une connexion, un livre d'or...",
        },
        {
          img: "labsolu",
          nom: "L'&nbsp;Absolu",
          sousTitre: "(2021-Aujourd'hui) Conception, design, front et back.",
          description: `
          Application PWA pour une salle de sport: suivi de ses performances en musculation, création de séance,
          visualisation sous forme de graphiques, contrôle de la musique de la salle...
          `,
        },
        {
          img: "placeholder",
          nom: "Votre projet ?",
          sousTitre: "Conception, design référencement, front, back...",
          description: `
          Un site web est un moyen de communication essentiel pour une entreprise, peu importe sa taille.
          `,
        },
      ],
      isMobile: window.matchMedia("(max-width: 720px)").matches,
      isTablet: window.matchMedia("(max-width: 1100px)").matches,
    };
  },
  computed: {
    exp() {
      const start = new Date("01/01/2016");
      const now = new Date();

      return new Date(now - start).getFullYear() - 1970;
    },
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.isMobile = window.matchMedia("(max-width: 720px)").matches;
      this.isTablet = window.matchMedia("(max-width: 1100px)").matches;
    });
    // When the user scrolls the page, execute myFunction
    window.addEventListener("scroll", () => {
      const headerContainer = document.querySelector("#header_container");
      const header = document.querySelector("header");
      const sticky = header.offsetTop + 10; // 10 = padding de 0.5 sur header
      if (window.pageYOffset > sticky) {
        headerContainer.classList.add("sticky");
        header.classList.add("sticky");
      } else {
        headerContainer.classList.remove("sticky");
        header.classList.remove("sticky");
      }
    });

    document.addEventListener("scroll", () => {
      const elements = document.querySelectorAll(".is-animated");
      [...elements].forEach((el) => {
        if (this.isInViewport(el)) {
          el.classList.add("active");
        }
      });
    });
  },
  methods: {
    isInViewport(el) {
      const rect = el.getBoundingClientRect();
      return rect.top <= (window.innerHeight || document.documentElement.clientHeight);
    },
  },
};
</script>
